import styles from './PricingFeatures.module.scss';
import React from 'react';
import { iconMap } from 'services';
import { Feature } from 'services/graphql/cms';
import Link from 'next/link';
import { ArrowRightOutlined } from '@ant-design/icons';

type Props = {
  feature: Feature;
  isHome?: boolean;
};

const FeaturesItem: React.FC<Props> = ({ feature, isHome }) => {
  const { title, description, badge, icon, carouselDescription } = feature;

  const featureIcon = iconMap(icon?.name);

  return (
    <div className={styles.container}>
      <div className={styles.containerContent}>
        <div className={styles.contentIcon}>{featureIcon}</div>

        <h4 className={styles.contentTitle}>{title}</h4>
        <div
          className={styles.contentText}
          dangerouslySetInnerHTML={{ __html: carouselDescription ? carouselDescription : description }}
        />

        {badge !== null ? <p className={styles.contentMore}>{badge}</p> : null}
      </div>
      {isHome && feature.link && (
        <Link href={feature.link} className={styles.contentLink}>
          Learn More <ArrowRightOutlined />
        </Link>
      )}
    </div>
  );
};

export default FeaturesItem;
