import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FC, useMemo } from 'react';
import styles from './CarouselControllersTop.module.scss';
import { CarouselControllersTopProps } from './CarouselControllersTop.props';
import cn from 'classnames';

const CarouselControllersTop: FC<CarouselControllersTopProps> = ({
  currentSlide,
  instanceRef,
  nrOfDots,
  shape = 'circle',
}) => {
  const hiddenSlides = useMemo(() => {
    if (instanceRef?.current?.slides && instanceRef?.current?.track.details.slidesLength) {
      return (
        instanceRef?.current?.slides.length -
        instanceRef?.current?.slides.length / instanceRef?.current?.track.details.slidesLength
      );
    }

    return 0;
  }, [instanceRef]);

  const disableNext = useMemo<boolean>(() => currentSlide >= Math.round(hiddenSlides), [currentSlide, hiddenSlides]);

  return (
    <div className={styles.carouselControllersTopWrapper}>
      <div className={styles.headActions}>
        <ul className={styles.actionsNav}>
          <li className={styles.navItem}>
            <Button
              shape={shape}
              onClick={(e: any) => e.stopPropagation() || instanceRef.current?.prev()}
              disabled={currentSlide === 0}
              icon={<LeftOutlined />}
            />
          </li>
          <li className={styles.navItem}>
            <Button
              disabled={disableNext}
              shape={shape}
              icon={<RightOutlined />}
              onClick={(e: any) => e.stopPropagation() || instanceRef.current?.next()}
            />
          </li>
        </ul>
        {nrOfDots > 0 && (
          <div className={styles.carouseDots}>
            {Array.from({ length: nrOfDots }, (_, i) => i).map((idx) => (
              <button
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx);
                }}
                className={cn(styles.carouseDotsItem, { [styles.carouseDotsItemActive]: currentSlide === idx })}
              ></button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export { CarouselControllersTop };
